@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

body {
  font-family: "Poppins", sans-serif;
  color: #9a8015;
}

.bcontent {
  background: #f2f6f9;
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background: violet;
}

span {
  display: block;
  margin-bottom: 20px;
}

.bcontent .tabs {
  border-bottom: 2px solid #d3d3d3;
}

.bcontent .tabs div {
  position: relative;
  top: 4px;
  padding: 5px 10px;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.bcontent .tabs div:hover {
  background: #eee;
  color: black;
  border-bottom: 2px solid #a9a9a9;
}

.bcontent .tabs .active, .bcontent .tabs .active:hover {
  border-bottom: 2px solid orange;
  background: #eee;
}

.race-title .game-id.mk8dx {
  background: #b9eee0
}

.race-title .game-id.mk8 {
  background: #d6eeb9
}

.race-title .game-id.mk7 {
  background: #eeedb9
}

.race-title .game-id.mkwii {
  background: #eed7b9
}

.sidebar div {
  padding: 10px 20px
}

.sidebar .header {
  padding-top: 25px
}

.sidebar .link:hover {
  background: rgba(9, 52, 115, 0.45);
  cursor: pointer
}

.mkr-header {
  background: rgb(16, 45, 83);
  border-bottom: 1px solid rgb(102, 140, 191);
}

.mkr-header .logo {
  height: 30px;
}

.mkr-header .sidebar-btn {
  border-color: #6865b6;
  color: #9b99cc;
}

.mkr-header .sidebar-btn:hover {
  border-color: #9b99cc;
  color: #b9b8c9;
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
}
