.mkr-table-wrapper {
	margin: 0;
	background-color: #f3f3f3;
	font-family: Futura, sans-serif;
}

.mkr-table-wrapper * {
	box-sizing: border-box;
}

.mkr-table-wrapper .table {
	width: 100%;
	border-collapse: collapse;
}

.mkr-table-wrapper .table td,
.mkr-table-wrapper .table th {
	padding: 12px 15px;
	text-align: center;
	font-size: 16px;
}

.mkr-table-wrapper .table th {
	background-color: #716eb6;
	padding: 18px 6px 18px 12px;
	color: #ffffff;
}

.mkr-table-wrapper .table tbody tr {
	color: #656060;
}

.mkr-table-wrapper .table tbody tr:nth-child(odd) {
	background-color: #e3e3e3;
}

.mkr-table-wrapper a:hover {
	color: black
}

.gold .table tbody tr:first-child, .gold-tr {
	background-color: #e8b92359 !important;
}

@media (max-width: 768px) {
	.mkr-table-wrapper .table thead {
		display: none;
	}

	.mkr-table-wrapper .table,
	.mkr-table-wrapper .table tbody,
	.mkr-table-wrapper .table tr,
	.mkr-table-wrapper .table td {
		display: block;
		width: 100%;
	}
	.mkr-table-wrapper .table tr {
		margin-bottom: 15px;
	}
	.mkr-table-wrapper .table td {
		padding-left: 50%;
		text-align: left;
		position: relative;
	}
	.mkr-table-wrapper .table td::before {
		content: attr(data-label);
		position: absolute;
		left: 0;
		width: 50%;
		padding-left: 15px;
		font-size: 15px;
		font-weight: bold;
		text-align: left;
	}
}
